import { render, staticRenderFns } from "./SigninRestorePassForm.vue?vue&type=template&id=8b48e502&scoped=true"
import script from "./SigninRestorePassForm.vue?vue&type=script&lang=js"
export * from "./SigninRestorePassForm.vue?vue&type=script&lang=js"
import style0 from "./SigninRestorePassForm.vue?vue&type=style&index=0&id=8b48e502&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b48e502",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SigninConfirmPhone: require('/usr/src/app/src/components/moleculus/signin/SigninConfirmPhone.vue').default,Input: require('/usr/src/app/src/components/atoms/Input.vue').default,Button: require('/usr/src/app/src/components/atoms/Button.vue').default})
