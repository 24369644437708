
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'SigninModal',
  components: {
    SigninAuthForm: () => import('~/components/moleculus/signin/SigninAuthForm.vue'),
    SigninRegForm: () => import('~/components/moleculus/signin/SigninRegForm.vue'),
    SigninRestorePassForm: () => import('~/components/moleculus/signin/SigninRestorePassForm.vue'),
  },
  computed: {
    ...mapGetters({
      ModalActive: 'signin/ModalActive',
      ModalType: 'signin/ModalType',
    }),
  },
  methods: {
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      SET_SIGNIN_TYPE: 'signin/SET_SIGNIN_TYPE',
    }),
    /**
     * Method cloze modal
     */
    closeModal() {
      this.SET_SIGNIN_ACTIVE(false)
      this.SET_SIGNIN_TYPE('auth')
    },
    /**
     * Method change sign in type
     * @param {String} type - sign in type
     */
    changeType(type) {
      if (this.ModalType !== type) {
        this.SET_SIGNIN_TYPE(type)
      }
    },
  },
}
