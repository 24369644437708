
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'SigninNewAuthForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    Input: () => import('~/components/atoms/Input.vue'),
    Button: () => import('~/components/atoms/Button.vue'),
    SigninConfirmPhone: () => import('~/components/moleculus/signin/SigninConfirmPhone.vue'),
  },

  data: () => ({
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    passwordConfirm: '',
    hideNotSavedFields: false,
    passwordType: true,
    passwordTypeConfirm: true,
    registrationErrors: null,
    disabledRegister: false,
  }),
  computed: {
    ...mapGetters({
      ModalType: 'signin/ModalType',
    }),
  },
  methods: {
    ...mapActions({
      userRegistration: 'signin/userRegistration',
      userAuthorization: 'signin/userAuthorization',
    }),
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      SET_CART: 'cart/SET_CART',
    }),
    /**
     * Method get correct phone value
     */
    checkPhone(phone) {
      if (phone) {
        this.phone = phone
      }
    },
    /**
     * Method registers and authorizes user and then closes modal
     */

    async authorization(evt, data) {
      evt.preventDefault()
      this.SET_SIGNIN_ACTIVE(false)
      await this.$auth.setUserToken(data.auth_key)
      await this.$auth.fetchUser()
      const authUser = this.$store.state.auth.user

      const cartId = authUser.cart !== null && authUser.cart.cart_id ? authUser.cart.cart_id : null
      this.SET_CART(cartId)

      this.$nextTick(() => {
        this.$nuxt.$loading.finish()
      })
    },
  },
}
