
import { mapActions, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'SigninRestorePassForm',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    phone: '',
    password: '',
    passwordConfirm: '',
    message: 'Впишите свой номер телефона в форму ниже.',
    //   'На ваш телефон мы отправим смс с кодом подтверждения. Введите код в поле снизу и придумайте новый пароль',
    hideCheckNumber: false,
    passwordDisabled: false,
    passwordType: true,
  }),

  methods: {
    ...mapActions({
      updateUserPassword: 'signin/updateUserPassword',
    }),
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      SET_SIGNIN_TYPE: 'signin/SET_SIGNIN_TYPE',
    }),
    /**
     * Method get correct phone value
     */
    checkPhone(phone) {
      if (phone) {
        this.phone = phone
      }
    },
    /**
     * Method check password valid and update them if is valid
     */
    async updatePassword(evt) {
      evt.preventDefault()
      this.$refs.restorePassForm.validate()

      if (this.$refs.restorePassForm.flags.valid) {
        this.passwordDisabled = true
        const { phone, password, passwordConfirm } = this

        const updatePassRes = await this.updateUserPassword({
          phone,
          password,
          passwordConfirm,
        })

        if (updatePassRes.errors) {
          this.$refs.restorePassForm.setErrors(updatePassRes.errors)
          this.passwordDisabled = false
        } else if (updatePassRes.data.status === 200) {
          this.passwordDisabled = false
          this.message = updatePassRes.data.detail
          this.phone = ''
          this.hideCheckNumber = true
          this.$services.toaster.add({
            type: 'success',
            title: '',
            description: 'Пароль успешно восстановлен',
          })
          this.SET_SIGNIN_ACTIVE(false)
          this.SET_SIGNIN_TYPE('auth')
        }
      }
    },
  },
}
