
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'SigninRegForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    Input: () => import('~/components/atoms/Input.vue'),
    Button: () => import('~/components/atoms/Button.vue'),
    SigninConfirmPhone: () => import('~/components/moleculus/signin/SigninConfirmPhone.vue'),
  },

  data: () => ({
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    passwordConfirm: '',
    hideNotSavedFields: false,
    passwordType: true,
    passwordTypeConfirm: true,
    registrationErrors: null,
    disabledRegister: false,
  }),
  computed: {
    ...mapGetters({
      ModalType: 'signin/ModalType',
    }),
  },
  methods: {
    ...mapActions({
      userRegistration: 'signin/userRegistration',
      userAuthorization: 'signin/userAuthorization',
    }),
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      SET_CART: 'cart/SET_CART',
    }),
    /**
     * Method get correct phone value
     */
    checkPhone(phone) {
      if (phone) {
        this.phone = phone
      }
    },
    /**
     * Method registers and authorizes user and then closes modal
     */
    async registration(evt) {
      evt.preventDefault()
      const result = await this.$refs.regForm.validate()
      if (result) {
        const { phone, email, first_name, last_name, password, passwordConfirm } = this

        this.disabledRegister = true
        try {
          const registrationRes = await this.$store.dispatch('rph-users/signUp', {
            phone,
            email,
            first_name,
            last_name,
            password,
            passwordConfirm,
          })
          if (registrationRes) {
            const authRes = await this.userAuthorization({
              phone,
              password,
            })
            if (authRes.errors) {
              this.hideNotSavedFields = false
              this.errors = JSON.parse(JSON.stringify(authRes.errors))
            } else if (authRes.data.status === 200) {
              await this.$auth.setUserToken(authRes.data.token)
              await this.$auth.fetchUser()
              const authUser = this.$store.state.auth.user
              const cartId =
                authUser.cart !== null && authUser.cart.cart_id ? authUser.cart.cart_id : null
              this.SET_CART(cartId)
              this.SET_SIGNIN_ACTIVE(false)
              this.$router.push('/')
            }
          }
          this.disabledRegister = false
        } catch (err) {
          if (err.response && err.response.data) {
            const messages = {}
            err.response.data.forEach((element) => {
              messages[element.field] = [element.message]
            })
            this.$refs.regForm.setErrors(messages)
          } else {
            console.log(e)
          }
          this.disabledRegister = false
        }
      }
    },
  },
}
