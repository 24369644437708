import { render, staticRenderFns } from "./SigninModal.vue?vue&type=template&id=221e5020"
import script from "./SigninModal.vue?vue&type=script&lang=js"
export * from "./SigninModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/src/components/atoms/Button.vue').default,SigninNewAuthForm: require('/usr/src/app/src/components/moleculus/signin/SigninNewAuthForm.vue').default,SigninRegForm: require('/usr/src/app/src/components/moleculus/signin/SigninRegForm.vue').default,SigninRestorePassForm: require('/usr/src/app/src/components/moleculus/signin/SigninRestorePassForm.vue').default})
